// eslint-disable-next-line import/no-anonymous-default-export
export default {
   url:{
      whatsapp: "https://chat.whatsapp.com/EfuycpTBB0NJlKMKJwFHhN",
      paginavenda: "https://tatianemodena.com/express",
   },
   api : {
      url: "https://llapi.leadlovers.com/webapi/lead",
      token: "9782BDF758194372895B3452D952555E",
      machineCode: 554993,
      emailSequenceCode: 1587491,
      sequenceLevelCode: 1,
   },
};